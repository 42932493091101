.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body{
  margin: 0;
  background-color:rgb(225, 219, 219);
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #3a3e45; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #282c34; 
}


.search-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(to right, #5c5c5c, #f5f5f5);
  padding: 30px;
  border-radius: 15px;
  transition: background 0.3s ease;
  perspective: 1000px;
}

.label {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  color: #999;
  pointer-events: none;
  transition: transform 0.3s ease, font-size 0.3s ease, color 0.3s ease,
    text-shadow 0.3s ease;
}

.search-input {
  position: relative;
  width: 600px;
  height: 40px;
  padding: 12px;
  border: 2px solid #ccc;
  border-radius: 8px;
  background: linear-gradient(to right, #d9d9d9, #f8f8f8);
  font-size: 16px;
  color: #555;
  outline: none;
  transition: box-shadow 0.3s ease, background 0.3s ease, color 0.3s ease,
    border-color 0.3s ease, transform 0.3s ease;
  transform-style: preserve-3d;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.2) inset; /* Improved box-shadow for a realistic feel */
}

.search-input:focus,
.search-input:not(:placeholder-shown) {
  border-color: #4caf50;
  background: linear-gradient(to right, #c2f0c2, #f0f0f0);
  transform: rotateX(20deg);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1), 0 0 10px rgba(0, 0, 0, 0.2) inset; /* Improved box-shadow on focus */
}

.search-input:focus + .label,
.search-input:not(:placeholder-shown) + .label {
  transform: translateY(-120%) scale(0.8);
  font-size: 12px;
  color: #4caf50;
  text-shadow: 0 0 5px rgba(76, 175, 80, 0.6);
}

.search-input:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1), 0 0 10px rgba(0, 0, 0, 0.2) inset; /* Improved box-shadow on hover */
  background: linear-gradient(to right, #e6e6e6, #f9f9f9);
  border-color: #999;
}

.clear-button {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  color: #999;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease, color 0.3s ease;
}

.clear-button:hover {
  color: #4caf50;
  transform: scale(1.1);
}

.search-input:not(:focus):not(:placeholder-shown) + .clear-button {
  opacity: 1;
}

.shine {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom right,
    rgba(255, 255, 255, 0.6),
    transparent
  );
  border-radius: 8px;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.search-input:focus + .shine {
  opacity: 1;
}

.search-icon {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  color: #999;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.3s ease, filter 0.3s ease;
  border-radius: 50%;
}

.search-input:focus + .search-icon {
  color: #4caf50;
  transform: translateY(-50%) scale(1.1);
  filter: drop-shadow(0 0 5px rgba(76, 175, 80, 0.6));
}

@keyframes inputPulse {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}

.search-input:focus {
  animation: inputPulse 0.5s ease;
}
